@import 'styles/includes.scss';

.Image {
    opacity: 0;
    transform: translateY(-50px);
    transition: opacity .5s, transform .5s;
    transition-delay: 0.2s;
}

.ImageInView {
    opacity: 1;
    transform: translateY(0);
}
